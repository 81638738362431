.app {
}

.body {
  padding: 20px;
}

h1 {
  font-size: 20px;
}

button {
  background-color: #007bff; /* Button background color */
  color: #fff; /* Button text color */
  border: none; /* No border */
  padding: 10px 20px; /* Padding */
  margin: 0 10px; /* Margin between buttons */
  cursor: pointer; /* Cursor style */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

button:hover {
  background-color: #0056b3; /* Darker background color on hover */
}

.wellcome {
  margin: auto;
  margin-top: 40px;
  width: fit-content;
  justify-content: center;
  align-content: center;
}

.logo {
  margin: auto;
  margin-top: 40px;
  height: 200px;
}

.title {
  font-size: 40px;
}
