.worker {
  margin-right: 7px;
}

.delete {
  cursor: pointer;
  transition: all ease-in 0.3s;
  color: tomato;
  &:hover {
    color: red;
  }
}
