.container {
  display: flex;
  height: 100vh;
  width: 100wh;
  overflow: hidden;
  background: linear-gradient(to right, white, lightblue);
}

.form {
  margin: auto;
}

.logo {
  height: 70px;
  margin-left: auto;
}

.loadingLogo {
  height: 70px;
  margin-left: auto;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
