.form {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .container {
    display: flex;
    align-content: center;
    justify-content: center;
  }
  
  .title {
    text-align: center;
    margin-top: 40px;
    font-size: 30px;
  }
  
  .button {
    width: 200px;
    margin-left: auto;
  }
  
  .successContainer {
    width: max-content;
    margin: auto;
  }
  
  .successContainer > * {
    margin-top: 50px;
  }
  
  .logout {
    margin-left: auto;
    background-color: transparent;
    color: red;
    width: 100px;
    height: 50px;
    transition: all ease 0.3s;
  
    &:hover {
      color: tomato;
      background-color: white;
    }
  }
  
  .name {
    text-align: center;
    font-weight: 600;
    margin-top: 10px
  }

  .checkinContainer {
    display: flex;

  }

  .checkin {
    margin: auto;
    margin-top: 30px
  }

  .cancel {
    color: red;
    cursor: pointer;
    text-align: center;
  }

  .card {
    display: flex;
    flex-direction: column;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background: linear-gradient(to bottom, rgb(201, 201, 255), rgb(192, 225, 255));
    color: black;
    border-radius: 6px;
    //box-shadow: 0 0 1px rgb(93, 167, 163);
    border: 1px solid rgb(231, 231, 231);
    max-width: 300px;
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
 

    > p {
        font-size: 12px;
        color: rgb(77, 77, 77);
        margin: 0px;
    }
    
  }

  .row {
    display: flex;
  }

  .index {
    margin-left: auto;
    color: grey;
    font-size: small;
  }

  .location {
    margin-top: 10px;
  }

  .strong {
    font-weight: 700;
  }

  .divider {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .total {
    margin-top: 10px;
    
  }

  .signedInMessage {
    text-align: center;
    margin-top: 15px;
    font-size: medium;
  }

  .selected {
    background-color: #007bff;
  
    &:hover {
      background-color: #007bff;
    }
  }

  .notSelected {
    background-color: #0037ad;
  
    &:hover {
      background-color: #0046dd;
    }
  }