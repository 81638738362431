.editRow {
  display: flex;
  flex-direction: column;
}

.form {
  margin: auto;
}

.editHeader {
  border-radius: 4px;
  margin-bottom: 20px;
}
