header {
  background-color: #3b3b3b; /* Dark background color */
  color: #fff; /* Text color */
  padding: 20px; /* Padding around content */
  text-align: center; /* Center-align text */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  font-family: Arial, sans-serif; /* Font family */

  display: flex;
  align-items: center;
  justify-items: center;
}

.headerButtons {
  margin-left: auto;
  margin-right: auto;
}

.title {
}

.logo {
  height: 50px;
 // filter: invert(100);
}

.selected {
  background-color: #007bff;

  &:hover {
    background-color: #007bff;
  }
}

.notSelected {
  background-color: #0037ad;

  &:hover {
    background-color: #0046dd;
  }
}

.logout {
  color: red;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: tomato;
  }
}

.refresh {
  font-weight: 600;
  cursor: pointer;
  margin-left: 20px;
  color: #007bff;
  transition: all 0.3s ease;

  &:hover {
    color: #429eff;
  }
}
