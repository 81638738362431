.editRow {
  display: flex;
  flex-direction: column;
}

.form {
  margin: auto;
}

.editHeader {
  border-radius: 4px;
  margin-bottom: 20px;
}

.delete {
  margin-left: auto;
  cursor: pointer;
  transition: all ease-in 0.3s;
  color: tomato;
  &:hover {
    color: red;
  }
}

/* .buttonRow {
  display: flex;
  flex-direction: row;
  background-color: green;
} */
