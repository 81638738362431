.form {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.container {
  display: flex;
  align-content: center;
  justify-content: center;
}

.title {
  text-align: center;
  margin: 40px;
  font-size: 30px;
}

.button {
  width: 200px;
  margin-left: auto;
}

.successContainer {
  width: max-content;
  margin: auto;
}

.successContainer > * {
  margin-top: 50px;
}

.logout {
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  color: red;
  width: 100px;
  height: 50px;
  transition: all ease 0.3s;

  &:hover {
    color: tomato;
    background-color: white;
  }
}
