.titleAndButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
}

.marginRight {
  margin-right: auto;
}

.formContainer {
  padding-top: 40px;
  border-radius: 4px;
  display: flex;
  width: max-content;
  margin: auto;
}

.selected {
  background-color: #007bff;

  &:hover {
    background-color: #007bff;
  }
}

.notSelected {
  background-color: #0037ad;

  &:hover {
    background-color: #0046dd;
  }
}
