.title {
  margin-bottom: 10px;
}
.delete {
  transition: all ease-in 0.3s;
  color: tomato;
  &:hover {
    color: red;
  }
}
